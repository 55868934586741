const script = document.createElement('script');
script.src =
  'https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.0.1/socket.io.js';
document.head.appendChild(script);

class ZaloLoginManager {
  constructor(options = {}) {
    this.socket = null;
    this.commit = options.commit;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectInterval = 3000;
    this.initialize();
  }

  initialize() {
    this.connectSocket();
  }

  connectSocket() {
    this.socket = io('https://cloudcenter.vn:5000/', {
      extraHeaders: {
        Authorization: '5wKt7Cq3okmneuRemtKj8dhp',
      },
      reconnection: true,
      reconnectionAttempts: this.maxReconnectAttempts,
      reconnectionDelay: this.reconnectInterval,
      timeout: 10000,
    });

    this.setupSocketListeners();
  }

  setupSocketListeners() {
    this.socket.on('connect', () => this.handleConnect());
    this.socket.on('disconnect', () => this.handleDisconnect());
    this.socket.on('connect_error', error => this.handleConnectError(error));
    this.socket.on('reconnect', attemptNumber =>
      this.handleReconnect(attemptNumber)
    );
    this.socket.on('reconnect_attempt', () => this.handleReconnectAttempt());
    this.socket.on('reconnect_failed', () => this.handleReconnectFailed());

    this.socket.on('qr_code', data => this.handleQRCode(data));
    this.socket.on('authentication_result', data =>
      this.handleAuthResult(data)
    );
    this.socket.on('Status', data => this.handleStatus(data));
    this.socket.on('user_authenticated', () => this.receiveLoading());
  }

  handleConnect() {
    console.log('Connected to server');
    console.log('Client SID:', this.socket.id);
    this.showStatus('Kết nối thành công', 'success');
    //   this.socket.emit('get_qr_code', { sid: this.socket.id });
    this.reconnectAttempts = 0;
  }

  handleDisconnect() {
    console.log('Disconnected from server');
    this.showStatus(
      'Mất kết nối với máy chủ - Vui lòng tải lại trang',
      'error'
    );
  }

  handleConnectError(error) {
    console.error('Connection error:', error);
    this.showStatus(
      `Lỗi kết nối: Vui lòng tải lại trang sau giây lát`,
      'error'
    );
  }

  handleReconnect(attemptNumber) {
    console.log(`Reconnected on attempt: ${attemptNumber}`);
    this.showStatus('Đã kết nối lại thành công!', 'success');
  }

  handleReconnectAttempt() {
    this.reconnectAttempts++;
    this.showStatus(
      `Đang thử kết nối lại... (${this.reconnectAttempts}/${this.maxReconnectAttempts})`,
      'connecting'
    );
  }

  handleReconnectFailed() {
    console.log('Failed to reconnect');
    this.showStatus(
      'Không thể kết nối lại với máy chủ. Vui lòng tải lại trang.',
      'error'
    );
  }

  showStatus(message, type) {
    console.log(`Status: ${message}, Type: ${type}`);
  }

  showSpinner(show) {
    console.log(`Spinner: ${show}`);
  }

  requestQRCode() {
    if (!this.socket?.connected) {
      this.showStatus(
        'Không thể kết nối với máy chủ, vui lòng tải lại trang',
        'error'
      );
      return;
    }

    console.log('Requesting QR code...');
    this.showSpinner(true);
    this.showStatus('Đang tạo mã QR...', 'connecting');
    this.socket.emit('get_qr_code');
  }

  receiveLoading() {
    console.log('Loading scan...');
    this.showStatus('Quét thành công, đang xử lý mã...', 'connecting');
    this.showSpinner(true);
  }

  handSetAccount() {
    if (!this.socket?.connected) {
      this.showStatus('Không thể kết nối với máy chủ', 'error');
      return;
    }

    console.log('Emitting set_account event');
    this.showStatus('Đang tạo tài khoản...', 'connecting');

    try {
      this.socket.emit('set_account');
    } catch (error) {
      console.error('Error emitting set_account:', error);
      this.showStatus('Lỗi khi gửi yêu cầu', 'error');
    }
  }

  handleQRCode(data) {
    console.log('Received QR code');
    this.showSpinner(false);
    this.showStatus('Vui lòng quét mã QR bằng ứng dụng Zalo', 'success');
  }

  handleDisconnectFromClient() {
    console.log('Client Disconnected!');
    this.socket.emit('disconnect_socket', { sid: this.socket.id });
    this.socket.disconnect();
  }

  handleAuthResult(data) {
    console.log('Authentication result:', data);
    this.showSpinner(false);
    if (data.status) {
      this.showStatus('Xác thực thành công!', 'success');
    } else {
      this.showStatus('Xác thực thất bại!', 'error');
    }
  }

  handleStatus(data) {
    console.log('Status update:', data);
    this.showStatus(data.message, data.status ? 'success' : 'error');
  }
}

export default ZaloLoginManager;
