const state = {
  itemRoot: localStorage.getItem('fileSystemCurrentPath') || '',
  currentPath: '',
};
const getters = {
  itemRoot: state => state.itemRoot,
  currentPath: state => state.currentPath,
};
const actions = {
  setBreadcrumb({ commit }, value) {
    commit('SET_VALUE', value);
  },
  setCurrentPath({ commit }, value) {
    commit('SET_PATH', value);
  },
};
const mutations = {
  SET_VALUE(state, value) {
    state.itemRoot = value;
    localStorage.setItem('fileSystemCurrentPath', value);
  },
  SET_PATH(state, value) {
    state.currentPath = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
