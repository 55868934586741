/* global axios */

import ApiClient from './ApiClient';

class FileSystemAccess extends ApiClient {
  constructor() {
    super('file_system_access', { accountScoped: true });
  }

  // async getLinkPreview(param) {
  //   try {
  //     return axios.post(`${this.url}/show_link_preview`, { url: param });
  //   } catch (error) {
  //     return null;
  //   }
  // }
  async getList(path) {
    try {
      return axios.get(`${this.url}/list_directory`, { params: { path } });
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  async fetchFile(path) {
    try {
      return axios.get(`${this.url}/fetch_file`, path);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
export default new FileSystemAccess();
