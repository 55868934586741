<template>
  <woot-modal
    :show="show"
    :on-close="onCancel"
    class=""
    :use-size="true"
    :size="'w-1/2'"
  >
    <div class="overflow-auto flex flex-col max-h-[860px] my-auto">
      <woot-modal-header
        :header-title="$t('CONVERSATION.MENU_CHANNEL.LABEL')"
        :header-content="$t('CONVERSATION.MENU_CHANNEL.SUB_TITLE')"
      />
      <div class="flex justify-end px-4">
        <woot-submit-button
          class="my-2"
          @click="saveChange"
          :button-text="'Cập nhật'"
        />
        <woot-submit-button
          class="my-2 mx-2"
          @click="clear"
          :button-text="'Bỏ chọn'"
          button-class="bg-red-600"
        />
      </div>
      <div
        class="grid w-full gap-4 mx-0 mb-5 mt-2 sm:grid-cols-[repeat(auto-fit,minmax(140px,1fr))]"
      >
        <div v-for="channel in menuItem" :key="channel.id" class="relative">
          <fluent-icon
            v-show="checkSelected(channel.id)"
            class="text-green-500 absolute left-2 top-[10px]"
            icon="checkmark-circle"
            type="solid"
            size="24"
          />
          <button
            v-tooltip.top="channel.label"
            class="bg-white text-ellipsis dark:bg-slate-900 cursor-pointer flex flex-col justify-end transition-all duration-200 ease-in py-2 px-0 items-center border border-solid border-slate-25 dark:border-slate-800 hover:border-woot-500 dark:hover:border-woot-500 hover:shadow-md hover:z-50 disabled:opacity-60"
            @click="handleList(channel)"
          >
            <img
              :src="getAvatar(channel)"
              :alt="channel.label"
              class="w-1/2 my-2 mx-auto"
            />
            <h3
              class="-webkit text-slate-800 min-h-10 px-2 w-36 line-clamp-2 dark:text-slate-100 text-xs text-center capitalize"
            >
              {{ channel.label }}
            </h3>
          </button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    menuItem: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedList: [],
    };
  },
  created() {
    const savedChannels = localStorage.getItem('selected_channel');
    if (savedChannels) {
      this.selectedList = JSON.parse(savedChannels);
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    getAvatar(channel) {
      if (channel.avatar_url) return channel.avatar_url;
      switch (channel.type) {
        case 'Channel::Api':
          return '/assets/images/dashboard/channels/zalo2.png';
        case 'Channel::Tiktok':
          return '/assets/images/dashboard/channels/tiktok.png';
        case 'Channel::WebWidget':
          return '/assets/images/dashboard/channels/web3.png';
        case 'Channel::FacebookPage':
          return '/assets/images/dashboard/channels/fb.png';
        case 'Channel::ZaloOfficialAccount':
          return '/assets/images/dashboard/channels/zaloa.png';
        default:
          return '/assets/images/dashboard/channels/api.png';
      }
    },
    checkSelected(channelId) {
      return this.selectedList.includes(channelId);
    },
    // getButtonStyle(channel) {
    //   const isSelected = checkSelected(channel.id);
    //   return {
    //     Color: isSelected ? channel.color : '',
    //   };
    // },
    handleList(channel) {
      const index = this.selectedList.indexOf(channel.id);

      if (index === -1) {
        this.selectedList.push(channel.id);
      } else {
        this.selectedList.splice(index, 1);
      }

      localStorage.setItem(
        'selected_channel',
        JSON.stringify(this.selectedList)
      );
    },
    saveChange() {
      window.location.reload();
    },
    clear() {
      localStorage.setItem('selected_channel', []);
      this.selectedList = [];
    },
  },
  computed: {
    // check(channelId) {
    //   return this.selectedList.includes(channelId);
    // },
  },
};
</script>
<style scoped lang="scss">
.v-tooltip-open {
  z-index: 9999 !important;
}
.has-tooltip {
  z-index: 9999 !important;
}
</style>
