import NotificationsAPI from '../../api/notifications';

const state = {
  notifications: [
    {
      id: 0,
      primary_actor_id: 0,
    },
  ],
  channels: [
    {
      id: 0,
      unread_number: 0,
    },
  ],
  allow_change: true,
};

const getters = {
  channels: stateChannels => stateChannels.channels,
  allow_change: stateAllowChange => stateAllowChange.allow_change,
};

const actions = {
  async countUnread({ commit }) {
    try {
      if (!state.allow_change) return;
      commit('SET_ALLOW');
      let listAll = [];
      const response = await NotificationsAPI.getNotification();
      // const response = await axios.get(
      //   `${HOST_URL}/api/v1/accounts/${accountId}/notifications/custom_index`,
      //   {
      //     headers: headers,
      //   }
      // );

      listAll = response.data;
      if (listAll.length > 0) {
        const filteredNotifications = listAll
          .filter(notification => notification.read_at === null)
          .map(notification => ({
            id: notification.id,
            primary_actor_id: notification.primary_actor_id,
          }));
        if (filteredNotifications) {
          commit('SET_NOTIFICATIONS', filteredNotifications);
        }

        const countMap = listAll.reduce((acc, item) => {
          const inboxId = item.inbox_id;
          if (item.read_at === null) {
            acc[inboxId] = (acc[inboxId] || 0) + 1;
          }
          return acc;
        }, {});

        const result = Object.keys(countMap).map(key => ({
          // eslint-disable-next-line
          id: parseInt(key),
          unread_number: countMap[key],
        }));

        commit('SET_CHANNELS', result);
        state.allow_change = true;
      }
    } catch (error) {
      /* empty */
    }
  },
  async deleteNotification(_, id) {
    try {
      const result = state.notifications.find(
        notification => notification.primary_actor_id === id
      );

      if (result) {
        await NotificationsAPI.delete(result.id);
        // await axios.delete(
        //   `${HOST_URL}/api/v1/accounts/${accountId}/notifications/${result.id}`,
        //   {
        //     headers: headers,
        //   }
        // );
      }
    } catch (error) {
      /* empty */
    }
  },
};
const mutations = {
  SET_CHANNELS(state1, channels) {
    state1.channels = channels;
  },
  SET_ALLOW(state2) {
    state2.allow_change = !state2.allow_change;
  },
  SET_NOTIFICATIONS(state3, data) {
    state3.notifications = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
