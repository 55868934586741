import io from 'socket.io-client';
import Swal from 'sweetalert2';
import routes from '../../routes/index';
import ZaloLoginManager from './zaloLoginManager';

const SET_LOADING = 'setLoading';
const SET_BTN = 'setBtn';
const SET_AUTH_STATUS = 'setAuthStatus';
const SET_NOTIFICATION = 'setNotification';
const SET_QR_CODE_IMAGE_SRC = 'setQrCodeImageSrc';
const SET_USER_INFO = 'setUserInfo';
const RESET_STATE = 'resetState';

const getDefaultState = () => ({
  loading: false,
  hiddenBtn: true,
  authStatus: null,
  notification: '',
  qrCodeImageSrc: '',
  userInfo: {
    username: '',
    phone: '',
    avatar: '',
  },
});

let zaloManager = null;
const state = getDefaultState();

const mutations = {
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_BTN](state, loading) {
    state.hiddenBtn = loading;
  },
  [SET_AUTH_STATUS](state, status) {
    state.authStatus = status;
  },
  [SET_NOTIFICATION](state, message) {
    state.notification = message;
  },
  [SET_QR_CODE_IMAGE_SRC](state, src) {
    state.qrCodeImageSrc = src;
  },
  [SET_USER_INFO](state, data) {
    state.userInfo = {
      username: data.username,
      phone: data.phone,
      avatar: data.avatar,
    };
  },
  [RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  initSocket({ commit }) {
    if (zaloManager) {
      zaloManager.socket?.disconnect();
    }

    zaloManager = new (class extends ZaloLoginManager {
      constructor() {
        super({ commit });
      }

      showStatus(message, type) {
        this.commit(SET_NOTIFICATION, message);
        if (type === 'error') {
          this.commit(SET_AUTH_STATUS, 'fail');
        }
      }

      showSpinner(show) {
        this.commit(SET_LOADING, show);
      }

      handleQRCode(data) {
        super.handleQRCode(data);
        this.commit(SET_QR_CODE_IMAGE_SRC, data.qr_code);
        this.socket.emit('handle_authentication');
      }

      handleAuthResult(data) {
        super.handleAuthResult(data);
        this.commit(SET_AUTH_STATUS, data.status ? 'success' : 'fail');
        this.commit(SET_QR_CODE_IMAGE_SRC, '');

        if (data.status) {
          console.log(1);
          this.commit(SET_USER_INFO, data.userInfo);
        }
      }

      handleStatus(data) {
        super.handleStatus(data);

        zaloManager.socket?.disconnect();
        zaloManager = null;
        if (data.status) {
          this.commit(SET_QR_CODE_IMAGE_SRC, '');
          Swal.fire({
            icon: 'success',
            title: 'Thông báo',
            text: data.message,
            timer: 5000,
            showConfirmButton: false,
          }).then(() => {
            routes.push({ name: 'settings_inbox_list' });
          });
          this.commit(RESET_STATE);
        } else {
          this.commit(RESET_STATE);
          this.commit(SET_NOTIFICATION, data.message);
        }
      }

      handleScan() {
        super.receiveLoading();
        this.commit(SET_LOADING, true);
      }

      handleConnect() {
        super.handleConnect();
        this.requestQRCode();
      }

      handleDisconnect() {
        super.handleDisconnect();
      }
    })();
  },

  callWebSocket({ commit }) {
    commit(RESET_STATE);
    commit(SET_LOADING, true);
    zaloManager?.requestQRCode();
    commit(SET_NOTIFICATION, 'Vui lòng chờ để hệ thống tạo mã QR');
  },

  callAcceptAccount({ commit }) {
    zaloManager?.handSetAccount();
    commit(SET_LOADING, true);
    commit(SET_NOTIFICATION, 'Đang khởi tạo tài khoản');
  },

  disconnect({ commit }) {
    if (zaloManager) {
      zaloManager.handleDisconnectFromClient();
      zaloManager = null;
      commit(RESET_STATE);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
