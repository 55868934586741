<script>
/* eslint-env browser */
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { useAccount } from 'dashboard/composables/useAccount';
import { required } from '@vuelidate/validators';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { mapGetters } from 'vuex';
import router from '../../../../index';

export default {
  components: {
    LoadingState,
  },
  setup() {
    const { accountId } = useAccount();
    return {
      accountId,
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      apiClient: '',
      isCreating: false,
      hasError: false,
      accessToken: '',
      refreshToken: '',
      channel: 'tiktok',
      selectedOA: { name: null, id: null },
      oaName: '',
      oaList: [],
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_TIKTOK'),
      errorStateMessage: '',
      errorStateDescription: '',
      hasLoginStarted: false,
      codeVerifier: '',
      codeChallenge: '',
      state: '',
      urlCallback: '',
      url: '',
      urlBase: '',
      envVariables: [],
    };
  },

  validations: {
    oaName: { required },
    selectedOA: {
      isEmpty() {
        return this.selectedOA !== null && !!this.selectedOA.name;
      },
    },
  },

  computed: {
    // ...mapGetters({
    //   envVariables: 'baseEnv/envVariables',
    // }),
    showLoader() {
      return !this.accessToken || this.isCreating;
    },
    getSelectableOAs() {
      return this.oaList.filter(item => !item.exists);
    },
  },
  async mounted() {
    const abc = await this.$store.dispatch('baseEnv/fetchBulkEnvVariables', [
      'VUE_APP_TIKTOK_AUTHEN',
      'VUE_APP_TIKTOK_CLIENT_KEY',
    ]);
    this.envVariables = abc;

    await this.createUrl();
  },
  methods: {
    generateCodeVerifier(length = 43) {
      const array = new Uint8Array(length);
      window.crypto.getRandomValues(array);

      const base64 = btoa(String.fromCharCode.apply(null, array))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');

      return base64;
    },

    async generateCodeChallenge(verifier) {
      const encoder = new TextEncoder();
      const data = encoder.encode(verifier);
      const digest = await window.crypto.subtle.digest('SHA-256', data);
      return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    },
    async createUrl() {
      try {
        const csrfState = Math.random().toString(36).substring(2);
        localStorage.setItem('csrfState', csrfState);

        this.urlCallback = `${window.location.origin}${window.location.pathname}`;

        this.urlBase = this.envVariables.VUE_APP_TIKTOK_AUTHEN;
        this.urlBase +=
          '?client_key=' + this.envVariables.VUE_APP_TIKTOK_CLIENT_KEY;
        this.urlBase +=
          '&scope=user.info.basic,user.info.profile,user.info.stats';
        this.urlBase += '&response_type=code';
        this.urlBase += '&redirect_uri=' + this.urlCallback;
        this.urlBase += '&state=' + csrfState;

        return this.urlBase;
      } catch (error) {
        useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
      }
    },

    async startLogin() {
      this.hasLoginStarted = true;

      const width = 600;
      const height = 700;
      const left = (window.innerWidth - width) / 2 + window.screenX;
      const top = (window.innerHeight - height) / 2 + window.screenY;

      const popup = window.open(
        this.urlBase,
        'tiktokAuthPopup',
        `width=${width},` +
          `height=${height},` +
          `left=${left},` +
          `top=${top},` +
          'scrollbars=yes,' +
          'status=1,' +
          'toolbar=no,' +
          'location=no,' +
          'menubar=no,' +
          'resizable=yes'
      );

      if (popup) {
        popup.focus();
        this.watchPopupUrl(popup);
      } else {
        useAlert(
          'Popup bị chặn bởi trình duyệt. Vui lòng cho phép popup từ trang web này và thử lại.'
        );
      }
    },

    watchPopupUrl(popup) {
      let interval;
      try {
        interval = setInterval(() => {
          try {
            if (popup.closed) {
              clearInterval(interval);
              return;
            }

            const currentUrl = popup.location.href;
            if (currentUrl.startsWith(this.urlCallback)) {
              clearInterval(interval);

              const urlObj = new URL(currentUrl);
              const code = urlObj.searchParams.get('code');
              const state = urlObj.searchParams.get('state');

              const storedState = localStorage.getItem('csrfState');
              localStorage.removeItem('csrfState');

              popup.close();

              if (state !== storedState) {
                useAlert('State mismatch - possible CSRF attack');
                throw new Error('State mismatch - possible CSRF attack');
              }

              if (code) {
                this.createChannel(code);
              } else {
                throw new Error('Missing required parameters from callback');
              }
            }
          } catch (e) {
            if (!e.message.includes('cross-origin')) {
              clearInterval(interval);
              popup.close();
              useAlert('Authentication failed: ' + e.message);
            }
          }
        }, 1000);

        setTimeout(
          () => {
            if (interval) {
              clearInterval(interval);
              if (!popup.closed) {
                popup.close();
              }
            }
          },
          2 * 60 * 1000
        );
      } catch (error) {
        if (interval) {
          clearInterval(interval);
        }
        if (popup && !popup.closed) {
          popup.close();
        }
        useAlert('Authentication failed: ' + error.message);
      }
    },
    createChannel(data) {
      this.v$.$touch();
      this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
      this.isCreating = true;
      this.$store
        .dispatch('inboxes/createTiktokChannel', data)
        .then(data => {
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: { page: 'new', inbox_id: data.id },
          });
        })
        .catch(() => {
          this.isCreating = false;
        });
    },
  },
};
</script>

<template>
  <div
    class="bg-[url('assets/images/channels/tiktok_bg.jpg')] bg-contain bg-no-repeat bg-center border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <div
      v-if="!hasLoginStarted"
      class="flex flex-col items-center justify-center h-full text-center"
    >
      <button
        v-show="envVariables"
        @click.prevent="startLogin"
        class="bg-white hover:bg-blue-600 text-black-800 font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
      >
        Đăng nhập với
        <span style="font-weight: 700; color: #006acc">Tiktok</span>
      </button>

      <p class="py-6">
        <!-- {{
          useInstallationName(
            $t('INBOX_MGMT.ADD.ZALO.HELP'),
            globalConfig.installationName
          )
        }} -->
      </p>
    </div>
    <div v-else>
      <div v-if="hasError" class="max-w-lg mx-auto text-center">
        <h5>{{ errorStateMessage }}</h5>
        <p
          v-if="errorStateDescription"
          v-dompurify-html="errorStateDescription"
        />
      </div>
      <LoadingState v-else-if="showLoader" :message="emptyStateMessage" />
    </div>
  </div>
</template>
