/* global axios */

import ApiClient from './ApiClient';

class EnvApiService extends ApiClient {
  constructor() {
    super('env', { accountScoped: true });
  }

  async getEnv(param) {
    try {
      return axios.get(`${this.url}/fetch_env`, {
        params: { param },
      });
    } catch (error) {
      // console.log('Error: ' + error);
      return null;
    }
  }

  async getBulkEnv(params) {
    try {
      return axios.get(`${this.url}/fetch_bulk_env`, {
        params: { params },
      });
    } catch (error) {
      // console.log('Error: ' + error);
      return null;
    }
  }

  async getChannel(param) {
    return axios.get(`${this.url}/get_channel`, {
      params: { param },
    });
  }

  async checkActive(param) {
    return axios.get(`${this.url}/check_account_active`, {
      params: { param },
    });
  }

  async checkContact(identifier) {
    return axios.get(`${this.url}/check_contact`, {
      params: { identifier },
    });
  }
}
export default new EnvApiService();
