<script setup>
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMapGetter } from 'dashboard/composables/store.js';

// const props = defineProps({
//   isShow: { default: false, type: Boolean },
// });
const { t } = useI18n();
const isFetching = useMapGetter('inboxes/fetchingMessage');
const isShow = useMapGetter('inboxes/isShowFetchingMessage');

const bannerText = computed(() => {
  if (isFetching.value) return t('NETWORK.NOTIFICATION.FETCHING_DATA');
  else return t('NETWORK.NOTIFICATION.FETCHING_SUCCESS');
});

const iconName = computed(() =>
  isFetching.value ? 'database' : 'checkmark-circle'
);
</script>

<template>
  <transition name="fetching-data-notification-fade" tag="div">
    <div v-show="isShow" class="fixed z-50 top-4 right-2 group">
      <div
        class="relative flex items-center justify-between w-full px-2 py-2 rounded-lg shadow-lg"
        :class="
          isFetching
            ? 'bg-woot-600 dark:bg-woot-700'
            : 'bg-green-600 dark:bg-green-700'
        "
      >
        <fluent-icon
          :icon="iconName"
          class="text-white dark:text-white"
          size="18"
        />
        <span
          class="px-2 text-xs font-medium tracking-wide text-white dark:text-white"
        >
          {{ bannerText }}
        </span>
      </div>
    </div>
  </transition>
</template>
