import EnvApiService from '../../api/EnvApiService';

export const state = () => ({
  envVariables: {},
  channelData: true,
});
export const getters = {
  envVariables: state => state.envVariables,
  channelData: state => state.channelData,
};
export const mutations = {
  SET_CHANNEL($state, channel) {
    $state.channelData = channel;
  },
  SET_CHANNEL_STATUS($state, status) {
    $state.channelData.status = status;
  },
  setEnvVariable(state, { param, value }) {
    state.envVariables = { ...state.envVariables, [param]: value };
  },
  setEnvBulkVariable(state, value) {
    state.envVariables = { ...state.envVariables, ...value };
  },
};

export const actions = {
  async fetchEnvVariables({ commit }, param) {
    try {
      const response = await EnvApiService.getEnv(param);
      const value = response.data.value;
      commit('setEnvVariable', { param, value });
      return value;
    } catch (error) {
      commit('setError', error.message);
      throw error;
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchBulkEnvVariables({ commit }, params) {
    try {
      const response = await EnvApiService.getBulkEnv(params);
      const value = response.data.values;
      commit('setEnvBulkVariable', value);
      return value;
    } catch (error) {
      console.error('Error fetching environment variables:', error);
      throw error;
    }
  },
  async getChannel({ commit }, param) {
    try {
      const response = await EnvApiService.getChannel(param);
      const value = response.data;
      commit('SET_CHANNEL', value);
    } catch (error) {
      console.error('Error get channel fail: ', error);
      throw error;
    }
  },

  updateChannelState({ commit }, value) {
    console.log('Trạng thái kênh đã thay đổi sang: ' + value);
    commit('SET_CHANNEL_STATUS', value);
  },

  async checkContact(_, identifier) {
    try {
      const response = await EnvApiService.checkContact(identifier);
      const value = response.data;
      return value;
    } catch (error) {
      console.error('Error get channel fail: ', error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
