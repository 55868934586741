/* global axios */
import ApiClient from '../ApiClient';

class TiktokChannel extends ApiClient {
  constructor() {
    super('tiktok_indicators', { accountScoped: true });
  }

  create(params) {
    const response = axios.post(
      `${this.url.replace(this.resource, '')}callbacks/get_access_token_tiktok`,
      { code: params }
    );
    console.log('Tiktok response', response);
    return response;
  }
}

export default new TiktokChannel();
