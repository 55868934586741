import FileSystemAccess from '../../api/fileSystemAccess';

const actions = {
  async getList(_, { path }) {
    try {
      const response = await FileSystemAccess.getList(path);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchFile(_, path) {
    try {
      const response = await FileSystemAccess.fetchFile(path);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
