<script>
/* eslint-env browser */
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { useAccount } from 'dashboard/composables/useAccount';
import { required } from '@vuelidate/validators';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { mapGetters } from 'vuex';
import router from '../../../../index';

export default {
  components: {
    LoadingState,
  },
  setup() {
    const { accountId } = useAccount();
    return {
      accountId,
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isCreating: false,
      hasError: false,
      accessToken: '',
      refreshToken: '',
      channel: 'zalo',
      selectedOA: { name: null, id: null },
      oaName: '',
      oaList: [],
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_ZOA'),
      errorStateMessage: '',
      errorStateDescription: '',
      hasLoginStarted: false,
      codeVerifier: '',
      codeChallenge: '',
      state: '',
      urlCallback: '',
      url: '',
      envVariables: [],
    };
  },

  validations: {
    oaName: { required },
    selectedOA: {
      isEmpty() {
        return this.selectedOA !== null && !!this.selectedOA.name;
      },
    },
  },

  computed: {
    showLoader() {
      return !this.accessToken || this.isCreating;
    },
    getSelectableOAs() {
      return this.oaList.filter(item => !item.exists);
    },
    // ...mapGetters({
    //   envVariables: 'baseEnv/envVariables',
    // }),
  },
  async mounted() {
    const value = await this.$store.dispatch('baseEnv/fetchBulkEnvVariables', [
      'VUE_APP_ZALO_AUTHEN',
      'VUE_APP_ZALO_AUTHOR',
      'VUE_APP_ZALO_APP_ID',
      'VUE_APP_ZALO_APP_SECRET',
      'VUE_APP_ZALO_OA',
    ]);
    this.envVariables = value;

    await this.createUrl();
  },
  methods: {
    generateCodeVerifier(length = 43) {
      const array = new Uint8Array(length);
      window.crypto.getRandomValues(array);

      const base64 = btoa(String.fromCharCode.apply(null, array))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');

      return base64;
    },

    async generateCodeChallenge(verifier) {
      const encoder = new TextEncoder();
      const data = encoder.encode(verifier);
      const digest = await window.crypto.subtle.digest('SHA-256', data);
      return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    },
    async createUrl() {
      try {
        this.codeVerifier = this.generateCodeVerifier();
        this.codeChallenge = await this.generateCodeChallenge(
          this.codeVerifier
        );
        this.state = this.codeChallenge;

        const REDIRECT_URI = `${window.location.origin}${window.location.pathname}`;
        const encodedUrl = encodeURIComponent(REDIRECT_URI);
        this.urlCallback = REDIRECT_URI;

        this.url =
          this.envVariables.VUE_APP_ZALO_AUTHEN +
          `?app_id=${this.envVariables.VUE_APP_ZALO_APP_ID}` +
          `&redirect_uri=${encodedUrl}` +
          `&code_challenge=${this.codeChallenge}` +
          `&state=${this.state}`;
      } catch (error) {
        useAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
      }
    },

    async startLogin() {
      this.hasLoginStarted = true;

      const width = 600;
      const height = 700;
      const left = (window.innerWidth - width) / 2 + window.screenX;
      const top = (window.innerHeight - height) / 2 + window.screenY;

      localStorage.setItem('state', this.state);
      localStorage.setItem('code_challenge', this.codeChallenge);
      localStorage.setItem('code_verifier', this.codeVerifier);

      const popup = window.open(
        this.url,
        'zaloAuthPopup',
        `width=${width},` +
          `height=${height},` +
          `left=${left},` +
          `top=${top},` +
          'scrollbars=yes,' +
          'status=1,' +
          'toolbar=no,' +
          'location=no,' +
          'menubar=no,' +
          'resizable=yes'
      );

      if (popup) {
        popup.focus();
        this.watchPopupUrl(popup);
      } else {
        useAlert(
          'Popup bị chặn bởi trình duyệt. Vui lòng cho phép popup từ trang web này và thử lại.'
        );
      }
    },

    watchPopupUrl(popup) {
      let interval;
      try {
        interval = setInterval(() => {
          try {
            if (popup.closed) {
              clearInterval(interval);
              return;
            }
            const currentUrl = popup.location.href;
            if (currentUrl.startsWith(this.urlCallback)) {
              clearInterval(interval);

              const urlObj = new URL(currentUrl);
              const code = urlObj.searchParams.get('code');
              const state = urlObj.searchParams.get('state');
              const oa_id = urlObj.searchParams.get('oa_id');

              const storedState = localStorage.getItem('state');
              const storedCodeVerifier = localStorage.getItem('code_verifier');

              localStorage.removeItem('state');
              localStorage.removeItem('code_challenge');
              localStorage.removeItem('code_verifier');

              popup.close();

              if (state !== storedState) {
                throw new Error('State mismatch - possible CSRF attack');
              }
              if (code && oa_id) {
                this.handleCallback({
                  code,
                  oa_id,
                  codeVerifier: storedCodeVerifier,
                });
              } else {
                throw new Error('Missing required parameters from callback');
              }
            }
          } catch (e) {
            if (!e.message.includes('cross-origin')) {
              clearInterval(interval);
              popup.close();
              useAlert('Authentication failed: ' + e.message);
            }
          }
        }, 1000);
        setTimeout(
          () => {
            if (interval) {
              clearInterval(interval);
              if (!popup.closed) {
                popup.close();
              }
            }
          },
          2 * 60 * 1000
        );
      } catch (error) {
        if (interval) {
          clearInterval(interval);
        }
        if (popup && !popup.closed) {
          popup.close();
        }
        useAlert('Authentication failed: ' + error.message);
      }
    },
    async handleCallback({ code, oa_id, codeVerifier }) {
      try {
        const formData = new URLSearchParams();
        formData.append('code', code);
        formData.append('app_id', this.envVariables.VUE_APP_ZALO_APP_ID);
        formData.append('grant_type', 'authorization_code');
        formData.append('code_verifier', codeVerifier);

        const response = await fetch(this.envVariables.VUE_APP_ZALO_AUTHOR, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            secret_key: this.envVariables.VUE_APP_ZALO_APP_SECRET,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        const response2 = await fetch(this.envVariables.VUE_APP_ZALO_OA, {
          method: 'GET',
          headers: {
            access_token: data.access_token,
          },
        });

        const infoPage = await response2.json();
        this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
        this.isCreating = true;

        const dataRequest = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expires_in: data.expires_in,
          zalo_id: oa_id,
          inbox_name: infoPage.data.name,
          avatar_url: infoPage.data.avatar,
        };

        this.createChannel(dataRequest);
      } catch (error) {
        console.log(error.message);
        useAlert('Failed to get access token: ' + error.message);
      }
    },

    handleAuthSuccess({ access_token, refresh_token, oa_id }) {
      this.access_token = access_token;
      this.refresh_token = refresh_token;
      this.oa_id = oa_id;
    },

    createChannel(data) {
      this.v$.$touch();
      this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
      this.isCreating = true;
      this.$store
        .dispatch('inboxes/createZaloChannel', data)
        .then(data => {
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: { page: 'new', inbox_id: data.id },
          });
        })
        .catch(() => {
          this.isCreating = false;
        });
    },
  },
};
</script>

<template>
  <div
    class="bg-[url('assets/images/channels/thumbnail_zalooa.jpg')] bg-contain bg-no-repeat bg-center border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <div
      v-if="!hasLoginStarted"
      class="flex flex-col items-center justify-center h-full text-center"
    >
      <button
        v-show="envVariables"
        @click.prevent="startLogin"
        class="bg-white hover:bg-blue-600 text-black-800 font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
      >
        Đăng nhập với Zalo Official Account
      </button>

      <p class="py-6">
        <!-- {{
          useInstallationName(
            $t('INBOX_MGMT.ADD.ZALO.HELP'),
            globalConfig.installationName
          )
        }} -->
      </p>
    </div>
    <div v-else>
      <div v-if="hasError" class="max-w-lg mx-auto text-center">
        <h5>{{ errorStateMessage }}</h5>
        <p
          v-if="errorStateDescription"
          v-dompurify-html="errorStateDescription"
        />
      </div>
      <LoadingState v-else-if="showLoader" :message="emptyStateMessage" />
    </div>
  </div>
</template>
