import axios from 'axios';
import LinkPreviewService from '../../api/linkPreview';

const actions = {
  async getLinkPreview(_, url) {
    try {
      const response = await LinkPreviewService.getLinkPreview(url);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
export default {
  namespaced: true,
  actions,
};
