import { createRouter, createWebHistory } from 'vue-router';

import { frontendURL } from '../helper/URLHelper';
import dashboard from './dashboard/dashboard.routes';
import store from 'dashboard/store';
import { validateLoggedInRoutes } from '../helper/routeHelpers';
import AnalyticsHelper from '../helper/AnalyticsHelper';
import { buildPermissionsFromRouter } from '../helper/permissionsHelper';
const publicRoutes = [
  // {
  //   path: '/',
  //   redirect: () => {
  //     const { getCurrentUser: user } = store.getters;
  //     return frontendURL(`accounts/${user.account_id}/dashboard`);
  //   },
  //   meta: {
  //     isPublic: true,
  //     permissions: [],
  //   },
  // },

  {
    path: '/app/private-policy',
    name: 'private-policy',
    component: () => import('../../widget/views/PrivatePolicy.vue'),
    meta: {
      isPublic: true,
      permissions: [],
    },
  },
  {
    path: '/app/term-of-service',
    name: 'term-of-service',
    component: () => import('../../widget/views/TermOfService.vue'),
    meta: {
      isPublic: true,
      permissions: [],
    },
  },
];
const routes = [...publicRoutes, ...dashboard.routes];

export const router = createRouter({ history: createWebHistory(), routes });
export const routesWithPermissions = buildPermissionsFromRouter(routes);

export const validateAuthenticateRoutePermission = (to, next) => {
  if (to.matched.some(record => record.meta.isPublic)) {
    return next();
  }

  const { isLoggedIn, getCurrentUser: user } = store.getters;

  if (!isLoggedIn) {
    window.location.assign('/app/login');
    return '';
  }

  if (!to.name) {
    return next(frontendURL(`accounts/${user.account_id}/dashboard`));
  }

  const nextRoute = validateLoggedInRoutes(to, store.getters.getCurrentUser);
  return nextRoute ? next(frontendURL(nextRoute)) : next();
};

export const initalizeRouter = () => {
  const userAuthentication = store.dispatch('setUser');

  router.beforeEach((to, _from, next) => {
    AnalyticsHelper.page(to.name || '', {
      path: to.path,
      name: to.name,
    });

    if (to.matched.some(record => record.meta.isPublic)) {
      return next();
    }

    userAuthentication.then(() => {
      return validateAuthenticateRoutePermission(to, next, store);
    });
  });
};

export default router;
