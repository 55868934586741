/* global axios */

import ApiClient from './ApiClient';

class LinkPreviewService extends ApiClient {
  constructor() {
    super('link_preview', { accountScoped: true });
  }

  async getLinkPreview(param) {
    try {
      return axios.post(`${this.url}/show_link_preview`, { url: param });
    } catch (error) {
      return null;
    }
  }
}
export default new LinkPreviewService();
